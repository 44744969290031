import React from "react"
import Header from '../../layouts/header'
import Canvas from '../../layouts/canvas'
import {Link, graphql} from 'gatsby'
import Footer from '../../layouts/footer'
import Breadcrumb from '../../layouts/breadcrumb'


import {Helmet} from 'react-helmet'

class Notfound extends React.Component {
  render() {
    var loc = [];//TODO pernaei san property sto breadcrumb, prepei na ginei function kai na to kano import giati einai idio se ola ta components
    var breadpath = this.props.location.pathname.split(/[/]/)
    breadpath.shift()
    breadpath.forEach((item,i) => {
      var value = null;
      var path = null;
      // console.log(item,i,loc[0])

      if(i === 0) {
        if(item === 'en' || item === 'el') {
          value = 'HOME';
          path=`/${item}`
        }
        else {
          value=item;
          path=`/${item}`
        }
        loc.push({value: value, path: path})
      }
      else {
        value=item;
        path=`${loc[i-1].path}/${item}`
        loc.push({value: value, path: path})
      }





    })
    return (
      <Canvas>
        <Header menu={this.props.data.drupal.menuel} pathname="/404" language="EL" langpath={[{url:'/en/404', id:'en'}, {url:'/el/404', id:'el'}]}/>
        <Breadcrumb title="ΔΕΝ ΒΡΕΘΗΚΕ" path={loc}/>

          <section className="ls s-pt-50 s-pb-100 error-404 not-found page_404">
    				<div className="container">
    					<div className="row">
    						<div className="col-sm-12 text-center">

    							<header className="page-header mb-5">
    								<p>
    									Η ΣΕΛΙΔΑ, ΔΕΝ ΒΡΕΘΗΚΕ
    								</p>
    							</header>


    							<div className="page-content">


    								<p>
                      <Link className="btn btn-maincolor" to='/el'>Αρχικη</Link>

    								</p>
    							</div>

    						</div>
    					</div>
    				</div>
    				<div className="d-none d-lg-block divider-120"></div>
    			</section>


        <Footer/>
      </Canvas>

    )
  }
}

export default Notfound

export const query = graphql `
{
  drupal {
  menuel: menuByName(name: "main", language: EL) {
  links {
    label
    url {
      path
    }
  }
}
}
}
`
